import { useMeta, useContext } from '@nuxtjs/composition-api'

type Seo = {
  title: string
  description: string
  keywords: string
}

export default function setSeo(data: Seo) {
  const { title, meta } = useMeta()
  title.value = data.title
  meta.value = [
    {
      hid: 'description',
      name: 'description',
      content: data.description,
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: data.keywords,
    },
  ]
}

export function userSeo(data: Seo, uMeta: ReturnType<typeof useMeta>) {
  const { title, meta } = uMeta
  title.value = data.title
  meta.value = [
    {
      hid: 'description',
      name: 'description',
      content: data.description,
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: data.keywords,
    },
  ]
}
export function setLanSeo(
  pageTitle = '',
  keyWords = '',
  description = '',
  setLan = ['zh_CN'],
  useMetaData = null as any,
  useContextData = null as any
) {
  const { title, meta } = useMetaData || useMeta()
  const { app } = useContextData || useContext()
  if (setLan.includes(app.i18n.locale || 'zh_CN')) {
    if (pageTitle) {
      title.value = pageTitle
    }
    const metaArr = []
    if (description) {
      metaArr.push({
        hid: 'description',
        name: 'description',
        content: description,
      })
    }
    if (keyWords) {
      metaArr.push({
        hid: 'keywords',
        name: 'keywords',
        content: keyWords,
      })
    }

    meta.value = metaArr
  }
}

/*
url有gclid，main_channel=engagelab_sem；
url有source=bd，main_channel=sem；
url有jiguang且没有（source=bd 或 gclid）， main_channel=nature
url有engagelab且没有（source=bd 或 gclid），main_channel=engagelab_nature
涉及接口：
/website/contact/sales
/organization/manage/create
*/

export function getMainChannel() {
  const firstVisitUrl = sessionStorage.getItem('first_visit_url') ?? ''
  let mainChannel = 'nature'
  if (/gclid/g.test(firstVisitUrl)) {
    mainChannel = 'engagelab_sem'
  } else if (/source=bd/g.test(firstVisitUrl)) {
    mainChannel = 'sem'
  } else if (/jiguang/g.test(firstVisitUrl)) {
    mainChannel = 'nature'
  } else if (/engagelab/g.test(firstVisitUrl)) {
    mainChannel = 'engagelab_nature'
  }
  return mainChannel
}
