import { getMainChannel } from './setSeo'
export default function addClue(route: any) {
  const mainChannel = getMainChannel()
  return {
    firstVisitUrl: sessionStorage.getItem('first_visit_url') || location.href,
    addFrom: sessionStorage.getItem('addFrom') || route.params.addFrom || 'EngageLab-AppPush',
    subChannel: 'new_reg',
    submissionUrl: location.href,
    mainChannel,
    language: navigator.language,
  }
}
