import { humanCheck } from '@/api/account'

export const initTurnstile = (data: {
  className: string
  language: string
  callback: (response: string) => void
  expiredCallback?: () => void
  errorCallback?: () => void
}) => {
  let language = 'en'
  if (data.language === 'en_US') {
    language = 'en'
  } else {
    language = data.language.replace('_', '-')
  }
  ;(window as any).turnstile?.render(data.className, {
    sitekey: '0x4AAAAAAAIxVDmhtyZOv8qF',
    language,
    theme: 'light',
    callback: (response: string) => {
      data.callback(response)
    },
    'expired-callback': data.expiredCallback,
    'error-callback': data.errorCallback,
  })
}
export const removeTurnstile = (id: string) => {
  ;(window as any).turnstile.remove()
  const ele = document.getElementById(id)
  if (ele) ele.innerHTML = ''
}

export const turnstileSuccess = (phone: string, lan: string, megTip: any, fuc: () => void, id = '#turnstileBox') => {
  initTurnstile({
    className: id,
    language: lan,
    callback: (token) => {
      humanCheck({
        turnstile: {
          token,
        },
        key: phone,
      }).then((res: any) => {
        if (res.code === 0) {
          removeTurnstile(id)
          fuc()
        } else {
          megTip.error(res.message)
        }
      })
    },
  })
}
