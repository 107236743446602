export default [
  {
    name: 'Afghanistan',
    flag: 'af',
    callingCode: '93',
  },
  {
    name: 'Åland Islands',
    flag: 'ax',
    callingCode: '358',
  },
  {
    name: 'Albania',
    flag: 'al',
    callingCode: '355',
  },
  {
    name: 'Algeria',
    flag: 'dz',
    callingCode: '213',
  },
  {
    name: 'American Samoa',
    flag: 'as',
    callingCode: '1684',
  },
  {
    name: 'Andorra',
    flag: 'ad',
    callingCode: '376',
  },
  {
    name: 'Angola',
    flag: 'ao',
    callingCode: '244',
  },
  {
    name: 'Anguilla',
    flag: 'ai',
    callingCode: '1264',
  },
  {
    name: 'Antarctica',
    flag: 'aq',
  },
  {
    name: 'Antigua and Barbuda',
    flag: 'ag',
    callingCode: '1268',
  },
  {
    name: 'Argentina',
    flag: 'ar',
    callingCode: '54',
  },
  {
    name: 'Armenia',
    flag: 'am',
    callingCode: '374',
  },
  {
    name: 'Aruba',
    flag: 'aw',
    callingCode: '297',
  },
  {
    name: 'Australia',
    flag: 'au',
    callingCode: '61',
  },
  {
    name: 'Austria',
    flag: 'at',
    callingCode: '43',
  },
  {
    name: 'Azerbaijan',
    flag: 'az',
    callingCode: '994',
  },
  {
    name: 'Bahamas',
    flag: 'bs',
    callingCode: '1242',
  },
  {
    name: 'Bahrain',
    flag: 'bh',
    callingCode: '973',
  },
  {
    name: 'Bangladesh',
    flag: 'bd',
    callingCode: '880',
  },
  {
    name: 'Barbados',
    flag: 'bb',
    callingCode: '1246',
  },
  {
    name: 'Belarus',
    flag: 'by',
    callingCode: '375',
  },
  {
    name: 'Belgium',
    flag: 'be',
    callingCode: '32',
  },
  {
    name: 'Belize',
    flag: 'bz',
    callingCode: '501',
  },
  {
    name: 'Benin',
    flag: 'bj',
    callingCode: '229',
  },
  {
    name: 'Bermuda',
    flag: 'bm',
    callingCode: '1441',
  },
  {
    name: 'Bhutan',
    flag: 'bt',
    callingCode: '975',
  },
  {
    name: 'Bolivia',
    flag: 'bo',
    callingCode: '591',
  },
  {
    name: 'Bonaire',
    flag: 'bq',
    callingCode: '5997',
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: 'ba',
    callingCode: '387',
  },
  {
    name: 'Botswana',
    flag: 'bw',
    callingCode: '267',
  },
  {
    name: 'Bouvet Island',
    flag: 'bv',
  },
  {
    name: 'Brazil',
    flag: 'br',
    callingCode: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    flag: 'io',
    callingCode: '246',
  },
  {
    name: 'British Virgin Islands',
    flag: 'vg',
    callingCode: '1284',
  },
  {
    name: 'Brunei',
    flag: 'bn',
    callingCode: '673',
  },
  {
    name: 'Bulgaria',
    flag: 'bg',
    callingCode: '359',
  },
  {
    name: 'Burkina Faso',
    flag: 'bf',
    callingCode: '226',
  },
  {
    name: 'Burundi',
    flag: 'bi',
    callingCode: '257',
  },
  {
    name: 'Cambodia',
    flag: 'kh',
    callingCode: '855',
  },
  {
    name: 'Cameroon',
    flag: 'cm',
    callingCode: '237',
  },
  {
    name: 'Canada',
    flag: 'ca',
    callingCode: '1',
  },
  {
    name: 'Cape Verde',
    flag: 'cv',
    callingCode: '238',
  },
  {
    name: 'Cayman Islands',
    flag: 'ky',
    callingCode: '1345',
  },
  {
    name: 'Central African Republic',
    flag: 'cf',
    callingCode: '236',
  },
  {
    name: 'Chad',
    flag: 'td',
    callingCode: '235',
  },
  {
    name: 'Chile',
    flag: 'cl',
    callingCode: '56',
  },
  {
    name: 'China',
    flag: 'cn',
    callingCode: '86',
  },
  {
    name: 'Christmas Island',
    flag: 'cx',
    callingCode: '61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    flag: 'cc',
    callingCode: '61',
  },
  {
    name: 'Colombia',
    flag: 'co',
    callingCode: '57',
  },
  {
    name: 'Comoros',
    flag: 'km',
    callingCode: '269',
  },
  {
    name: 'Republic of the Congo',
    flag: 'cg',
    callingCode: '242',
  },
  {
    name: 'DR Congo',
    flag: 'cd',
    callingCode: '243',
  },
  {
    name: 'Cook Islands',
    flag: 'ck',
    callingCode: '682',
  },
  {
    name: 'Costa Rica',
    flag: 'cr',
    callingCode: '506',
  },
  {
    name: 'Croatia',
    flag: 'hr',
    callingCode: '385',
  },
  {
    name: 'Cuba',
    flag: 'cu',
    callingCode: '53',
  },
  {
    name: 'Curaçao',
    flag: 'cw',
    callingCode: '5999',
  },
  {
    name: 'Cyprus',
    flag: 'cy',
    callingCode: '357',
  },
  {
    name: 'Czech Republic',
    flag: 'cz',
    callingCode: '420',
  },
  {
    name: 'Denmark',
    flag: 'dk',
    callingCode: '45',
  },
  {
    name: 'Djibouti',
    flag: 'dj',
    callingCode: '253',
  },
  {
    name: 'Dominica',
    flag: 'dm',
    callingCode: '1767',
  },
  {
    name: 'Dominican Republic',
    flag: 'do',
    callingCode: '1809',
  },
  {
    name: 'Ecuador',
    flag: 'ec',
    callingCode: '593',
  },
  {
    name: 'Egypt',
    flag: 'eg',
    callingCode: '20',
  },
  {
    name: 'El Salvador',
    flag: 'sv',
    callingCode: '503',
  },
  {
    name: 'Equatorial Guinea',
    flag: 'gq',
    callingCode: '240',
  },
  {
    name: 'Eritrea',
    flag: 'er',
    callingCode: '291',
  },
  {
    name: 'Estonia',
    flag: 'ee',
    callingCode: '372',
  },
  {
    name: 'Ethiopia',
    flag: 'et',
    callingCode: '251',
  },
  {
    name: 'Falkland Islands',
    flag: 'fk',
    callingCode: '500',
  },
  {
    name: 'Faroe Islands',
    flag: 'fo',
    callingCode: '298',
  },
  {
    name: 'Fiji',
    flag: 'fj',
    callingCode: '679',
  },
  {
    name: 'Finland',
    flag: 'fi',
    callingCode: '358',
  },
  {
    name: 'France',
    flag: 'fr',
    callingCode: '33',
  },
  {
    name: 'French Guiana',
    flag: 'gf',
    callingCode: '594',
  },
  {
    name: 'French Polynesia',
    flag: 'pf',
    callingCode: '689',
  },
  {
    name: 'French Southern and Antarctic Lands',
    flag: 'tf',
  },
  {
    name: 'Gabon',
    flag: 'ga',
    callingCode: '241',
  },
  {
    name: 'Gambia',
    flag: 'gm',
    callingCode: '220',
  },
  {
    name: 'Georgia',
    flag: 'ge',
    callingCode: '995',
  },
  {
    name: 'Germany',
    flag: 'de',
    callingCode: '49',
  },
  {
    name: 'Ghana',
    flag: 'gh',
    callingCode: '233',
  },
  {
    name: 'Gibraltar',
    flag: 'gi',
    callingCode: '350',
  },
  {
    name: 'Greece',
    flag: 'gr',
    callingCode: '30',
  },
  {
    name: 'Greenland',
    flag: 'gl',
    callingCode: '299',
  },
  {
    name: 'Grenada',
    flag: 'gd',
    callingCode: '1473',
  },
  {
    name: 'Guadeloupe',
    flag: 'gp',
    callingCode: '590',
  },
  {
    name: 'Guam',
    flag: 'gu',
    callingCode: '1671',
  },
  {
    name: 'Guatemala',
    flag: 'gt',
    callingCode: '502',
  },
  {
    name: 'Guernsey',
    flag: 'gg',
    callingCode: '44',
  },
  {
    name: 'Guinea',
    flag: 'gn',
    callingCode: '224',
  },
  {
    name: 'Guinea-Bissau',
    flag: 'gw',
    callingCode: '245',
  },
  {
    name: 'Guyana',
    flag: 'gy',
    callingCode: '592',
  },
  {
    name: 'Haiti',
    flag: 'ht',
    callingCode: '509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    flag: 'hm',
  },
  {
    name: 'Vatican City',
    flag: 'va',
    callingCode: '3906698',
  },
  {
    name: 'Honduras',
    flag: 'hn',
    callingCode: '504',
  },
  {
    name: 'Hong Kong(China)',
    flag: 'hk',
    callingCode: '852',
  },
  {
    name: 'Hungary',
    flag: 'hu',
    callingCode: '36',
  },
  {
    name: 'Iceland',
    flag: 'is',
    callingCode: '354',
  },
  {
    name: 'India',
    flag: 'in',
    callingCode: '91',
  },
  {
    name: 'Indonesia',
    flag: 'id',
    callingCode: '62',
  },
  {
    name: 'Ivory Coast',
    flag: 'ci',
    callingCode: '225',
  },
  {
    name: 'Iran',
    flag: 'ir',
    callingCode: '98',
  },
  {
    name: 'Iraq',
    flag: 'iq',
    callingCode: '964',
  },
  {
    name: 'Ireland',
    flag: 'ie',
    callingCode: '353',
  },
  {
    name: 'Isle of Man',
    flag: 'im',
    callingCode: '44',
  },
  {
    name: 'Israel',
    flag: 'il',
    callingCode: '972',
  },
  {
    name: 'Italy',
    flag: 'it',
    callingCode: '39',
  },
  {
    name: 'Jamaica',
    flag: 'jm',
    callingCode: '1876',
  },
  {
    name: 'Japan',
    flag: 'jp',
    callingCode: '81',
  },
  {
    name: 'Jersey',
    flag: 'je',
    callingCode: '44',
  },
  {
    name: 'Jordan',
    flag: 'jo',
    callingCode: '962',
  },
  {
    name: 'Kazakhstan',
    flag: 'kz',
    callingCode: '76',
  },
  {
    name: 'Kenya',
    flag: 'ke',
    callingCode: '254',
  },
  {
    name: 'Kiribati',
    flag: 'ki',
    callingCode: '686',
  },
  {
    name: 'Kuwait',
    flag: 'kw',
    callingCode: '965',
  },
  {
    name: 'Kyrgyzstan',
    flag: 'kg',
    callingCode: '996',
  },
  {
    name: 'Laos',
    flag: 'la',
    callingCode: '856',
  },
  {
    name: 'Latvia',
    flag: 'lv',
    callingCode: '371',
  },
  {
    name: 'Lebanon',
    flag: 'lb',
    callingCode: '961',
  },
  {
    name: 'Lesotho',
    flag: 'ls',
    callingCode: '266',
  },
  {
    name: 'Liberia',
    flag: 'lr',
    callingCode: '231',
  },
  {
    name: 'Libya',
    flag: 'ly',
    callingCode: '218',
  },
  {
    name: 'Liechtenstein',
    flag: 'li',
    callingCode: '423',
  },
  {
    name: 'Lithuania',
    flag: 'lt',
    callingCode: '370',
  },
  {
    name: 'Luxembourg',
    flag: 'lu',
    callingCode: '352',
  },
  {
    name: 'Macau',
    flag: 'mo',
    callingCode: '853',
  },
  {
    name: 'Macedonia',
    flag: 'mk',
    callingCode: '389',
  },
  {
    name: 'Madagascar',
    flag: 'mg',
    callingCode: '261',
  },
  {
    name: 'Malawi',
    flag: 'mw',
    callingCode: '265',
  },
  {
    name: 'Malaysia',
    flag: 'my',
    callingCode: '60',
  },
  {
    name: 'Maldives',
    flag: 'mv',
    callingCode: '960',
  },
  {
    name: 'Mali',
    flag: 'ml',
    callingCode: '223',
  },
  {
    name: 'Malta',
    flag: 'mt',
    callingCode: '356',
  },
  {
    name: 'Marshall Islands',
    flag: 'mh',
    callingCode: '692',
  },
  {
    name: 'Martinique',
    flag: 'mq',
    callingCode: '596',
  },
  {
    name: 'Mauritania',
    flag: 'mr',
    callingCode: '222',
  },
  {
    name: 'Mauritius',
    flag: 'mu',
    callingCode: '230',
  },
  {
    name: 'Mayotte',
    flag: 'yt',
    callingCode: '262',
  },
  {
    name: 'Mexico',
    flag: 'mx',
    callingCode: '52',
  },
  {
    name: 'Micronesia',
    flag: 'fm',
    callingCode: '691',
  },
  {
    name: 'Moldova',
    flag: 'md',
    callingCode: '373',
  },
  {
    name: 'Monaco',
    flag: 'mc',
    callingCode: '377',
  },
  {
    name: 'Mongolia',
    flag: 'mn',
    callingCode: '976',
  },
  {
    name: 'Montenegro',
    flag: 'me',
    callingCode: '382',
  },
  {
    name: 'Montserrat',
    flag: 'ms',
    callingCode: '1664',
  },
  {
    name: 'Morocco',
    flag: 'ma',
    callingCode: '212',
  },
  {
    name: 'Mozambique',
    flag: 'mz',
    callingCode: '258',
  },
  {
    name: 'Myanmar',
    flag: 'mm',
    callingCode: '95',
  },
  {
    name: 'Namibia',
    flag: 'na',
    callingCode: '264',
  },
  {
    name: 'Nauru',
    flag: 'nr',
    callingCode: '674',
  },
  {
    name: 'Nepal',
    flag: 'np',
    callingCode: '977',
  },
  {
    name: 'Netherlands',
    flag: 'nl',
    callingCode: '31',
  },
  {
    name: 'New Caledonia',
    flag: 'nc',
    callingCode: '687',
  },
  {
    name: 'New Zealand',
    flag: 'nz',
    callingCode: '64',
  },
  {
    name: 'Nicaragua',
    flag: 'ni',
    callingCode: '505',
  },
  {
    name: 'Niger',
    flag: 'ne',
    callingCode: '227',
  },
  {
    name: 'Nigeria',
    flag: 'ng',
    callingCode: '234',
  },
  {
    name: 'Niue',
    flag: 'nu',
    callingCode: '683',
  },
  {
    name: 'Norfolk Island',
    flag: 'nf',
    callingCode: '672',
  },
  {
    name: 'North Korea',
    flag: 'kp',
    callingCode: '850',
  },
  {
    name: 'Northern Mariana Islands',
    flag: 'mp',
    callingCode: '1670',
  },
  {
    name: 'Norway',
    flag: 'no',
    callingCode: '47',
  },
  {
    name: 'Oman',
    flag: 'om',
    callingCode: '968',
  },
  {
    name: 'Pakistan',
    flag: 'pk',
    callingCode: '92',
  },
  {
    name: 'Palau',
    flag: 'pw',
    callingCode: '680',
  },
  {
    name: 'Palestine',
    flag: 'ps',
    callingCode: '970',
  },
  {
    name: 'Panama',
    flag: 'pa',
    callingCode: '507',
  },
  {
    name: 'Papua New Guinea',
    flag: 'pg',
    callingCode: '675',
  },
  {
    name: 'Paraguay',
    flag: 'py',
    callingCode: '595',
  },
  {
    name: 'Peru',
    flag: 'pe',
    callingCode: '51',
  },
  {
    name: 'Philippines',
    flag: 'ph',
    callingCode: '63',
  },
  {
    name: 'Pitcairn Islands',
    flag: 'pn',
    callingCode: '64',
  },
  {
    name: 'Poland',
    flag: 'pl',
    callingCode: '48',
  },
  {
    name: 'Portugal',
    flag: 'pt',
    callingCode: '351',
  },
  {
    name: 'Puerto Rico',
    flag: 'pr',
    callingCode: '1787',
  },
  {
    name: 'Qatar',
    flag: 'qa',
    callingCode: '974',
  },
  {
    name: 'Kosovo',
    flag: 'xk',
    callingCode: '377',
  },
  {
    name: 'Réunion',
    flag: 're',
    callingCode: '262',
  },
  {
    name: 'Romania',
    flag: 'ro',
    callingCode: '40',
  },
  {
    name: 'Russia',
    flag: 'ru',
    callingCode: '7',
  },
  {
    name: 'Rwanda',
    flag: 'rw',
    callingCode: '250',
  },
  {
    name: 'Saint Barthélemy',
    flag: 'bl',
    callingCode: '590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    flag: 'sh',
    callingCode: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: 'kn',
    callingCode: '1869',
  },
  {
    name: 'Saint Lucia',
    flag: 'lc',
    callingCode: '1758',
  },
  {
    name: 'Saint Martin',
    flag: 'mf',
    callingCode: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: 'pm',
    callingCode: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: 'vc',
    callingCode: '1784',
  },
  {
    name: 'Samoa',
    flag: 'ws',
    callingCode: '685',
  },
  {
    name: 'San Marino',
    flag: 'sm',
    callingCode: '378',
  },
  {
    name: 'São Tomé and Príncipe',
    flag: 'st',
    callingCode: '239',
  },
  {
    name: 'Saudi Arabia',
    flag: 'sa',
    callingCode: '966',
  },
  {
    name: 'Senegal',
    flag: 'sn',
    callingCode: '221',
  },
  {
    name: 'Serbia',
    flag: 'rs',
    callingCode: '381',
  },
  {
    name: 'Seychelles',
    flag: 'sc',
    callingCode: '248',
  },
  {
    name: 'Sierra Leone',
    flag: 'sl',
    callingCode: '232',
  },
  {
    name: 'Singapore',
    flag: 'sg',
    callingCode: '65',
  },
  {
    name: 'Sint Maarten',
    flag: 'sx',
    callingCode: '1721',
  },
  {
    name: 'Slovakia',
    flag: 'sk',
    callingCode: '421',
  },
  {
    name: 'Slovenia',
    flag: 'si',
    callingCode: '386',
  },
  {
    name: 'Solomon Islands',
    flag: 'sb',
    callingCode: '677',
  },
  {
    name: 'Somalia',
    flag: 'so',
    callingCode: '252',
  },
  {
    name: 'South Africa',
    flag: 'za',
    callingCode: '27',
  },
  {
    name: 'South Georgia',
    flag: 'gs',
    callingCode: '500',
  },
  {
    name: 'South Korea',
    flag: 'kr',
    callingCode: '82',
  },
  {
    name: 'South Sudan',
    flag: 'ss',
    callingCode: '211',
  },
  {
    name: 'Spain',
    flag: 'es',
    callingCode: '34',
  },
  {
    name: 'Sri Lanka',
    flag: 'lk',
    callingCode: '94',
  },
  {
    name: 'Sudan',
    flag: 'sd',
    callingCode: '249',
  },
  {
    name: 'Suriname',
    flag: 'sr',
    callingCode: '597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: 'sj',
    callingCode: '4779',
  },
  {
    name: 'Swaziland',
    flag: 'sz',
    callingCode: '268',
  },
  {
    name: 'Sweden',
    flag: 'se',
    callingCode: '46',
  },
  {
    name: 'Switzerland',
    flag: 'ch',
    callingCode: '41',
  },
  {
    name: 'Syria',
    flag: 'sy',
    callingCode: '963',
  },
  {
    name: 'Taiwan(China)',
    flag: 'tw',
    callingCode: '886',
  },
  {
    name: 'Tajikistan',
    flag: 'tj',
    callingCode: '992',
  },
  {
    name: 'Tanzania',
    flag: 'tz',
    callingCode: '255',
  },
  {
    name: 'Thailand',
    flag: 'th',
    callingCode: '66',
  },
  {
    name: 'Timor-Leste',
    flag: 'tl',
    callingCode: '670',
  },
  {
    name: 'Togo',
    flag: 'tg',
    callingCode: '228',
  },
  {
    name: 'Tokelau',
    flag: 'tk',
    callingCode: '690',
  },
  {
    name: 'Tonga',
    flag: 'to',
    callingCode: '676',
  },
  {
    name: 'Trinidad and Tobago',
    flag: 'tt',
    callingCode: '1868',
  },
  {
    name: 'Tunisia',
    flag: 'tn',
    callingCode: '216',
  },
  {
    name: 'Turkey',
    flag: 'tr',
    callingCode: '90',
  },
  {
    name: 'Turkmenistan',
    flag: 'tm',
    callingCode: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    flag: 'tc',
    callingCode: '1649',
  },
  {
    name: 'Tuvalu',
    flag: 'tv',
    callingCode: '688',
  },
  {
    name: 'Uganda',
    flag: 'ug',
    callingCode: '256',
  },
  {
    name: 'Ukraine',
    flag: 'ua',
    callingCode: '380',
  },
  {
    name: 'United Arab Emirates',
    flag: 'ae',
    callingCode: '971',
  },
  {
    name: 'United Kingdom',
    flag: 'gb',
    callingCode: '44',
  },
  {
    name: 'United States',
    flag: 'us',
    callingCode: '1',
  },
  {
    name: 'United States Minor Outlying Islands',
    flag: 'um',
  },
  {
    name: 'United States Virgin Islands',
    flag: 'vi',
    callingCode: '1340',
  },
  {
    name: 'Uruguay',
    flag: 'uy',
    callingCode: '598',
  },
  {
    name: 'Uzbekistan',
    flag: 'uz',
    callingCode: '998',
  },
  {
    name: 'Vanuatu',
    flag: 'vu',
    callingCode: '678',
  },
  {
    name: 'Venezuela',
    flag: 've',
    callingCode: '58',
  },
  {
    name: 'Vietnam',
    flag: 'vn',
    callingCode: '84',
  },
  {
    name: 'Wallis and Futuna',
    flag: 'wf',
    callingCode: '681',
  },
  {
    name: 'Western Sahara',
    flag: 'eh',
    callingCode: '212',
  },
  {
    name: 'Yemen',
    flag: 'ye',
    callingCode: '967',
  },
  {
    name: 'Zambia',
    flag: 'zm',
    callingCode: '260',
  },
  {
    name: 'Zimbabwe',
    flag: 'zw',
    callingCode: '263',
  },
]
