import { getJson, postJson, deteleJson, putJson } from '@/utils/api'
import config from '@/utils/config'

export const submitForm = (params: any) => {
  return postJson(`/api/console/website/contact/sales`, params)
}
// 账号信息 ------
// 检查邮箱是否存在 /account/info/check-email-exists{?email}
export const getEmailExistsStatus = (params: any) => {
  return getJson(`/api/console/account/info/check-email-exists`, { params })
}
// 查询当前登录账号的信息 /account/info/current
export const getUserInfo = () => {
  return getJson(`/api/console/account/info/current`)
}
// 邀请成员 - 接受邀请
export const getinviteAccept = (params: any) => {
  return getJson(`/api/console/organization/member/invite/accept`, { params })
}
// 账号注册 ------
// 账号注册步骤1 - 提交邮箱和密码
export const requestSignupStart = (params: any) => {
  return postJson(`/api/console/account/register/start`, params)
}
// 账号注册步骤2 - 提交验证码
export const requestSignupConfirm = (params: any) => {
  return postJson(`/api/console/account/register/confirm`, Object.assign(params, { language: navigator.language }))
}
// 账号注册 - 来自邀请
export const requestSignupByInvite = (params: any) => {
  return postJson(`/api/console/account/register/by-invite`, params)
}
// 账号登录 ------
// 使用邮箱账号登录
export const requestSigninByEmail = (params: any) => {
  return postJson(`/api/console/account/login/by-email`, params)
}
// 使用github账号登录
export const requestSigninByGithub = (params: any) => {
  return postJson(`/api/console/account/github`, params)
}
// 使用谷歌账号登录
export const requestSigninByGoogle = (params: any) => {
  return postJson(`/api/console/account/google`, params)
}
// 账号密码 ------
// 忘记密码 - 发送验证邮件
export const requestPwdForgetSend = (params: any) => {
  return postJson(`/api/console/account/pwd/forget/send`, params)
}
// 忘记密码 - 检查验证码是否正确
export const requestPwdForgetCheck = (params: any) => {
  return postJson(`/api/console/account/pwd/forget/check`, params)
}
// 忘记密码 - 确认重置密码
export const requestPwdForgetReset = (params: any) => {
  return postJson(`/api/console/account/pwd/forget/reset`, params)
}
// 组织管理 ------
// 创建组织 /organization/manage/create
export const requestCreateOrg = (params: any) => {
  return postJson(`/api/console/organization/manage/create`, Object.assign(params, { language: navigator.language }))
}
// 绑定组织
export const requestBindOrg = (OrgId: number, params: any) => {
  return postJson(`/api/console/organization/manage/o-${OrgId}/bind`, params)
}
// 账号下可绑定的组织列表
export const requestGetOrgList = (params: any) => {
  return getJson(`/api/console/organization/info/bind/station/list`, { params })
}
// 检查组织名字/网址是否存在
export const checkOrganizationInfo = (params: any) => {
  return getJson(`/api/console/organization/info/exist`, { params })
}
const base = '/api/console/account/verify/code/'
const base1 = '/api/console/account/'
// 得到手机验证码
export const phoneSend = (params: { areaCode: string; phone: string }, isRegister = false) => {
  params = Object.assign({ verificationType: 'PHONE', option: isRegister ? 'REGISTER' : 'SEND_CODE' }, params)
  return postJson(`${base}phone/send`, params)
}
// 补充用户信息
export const verifyInfo = (params: { areaCode: string; phone: string; code: string; firstName: string; lastName: string }) => {
  params = Object.assign({ verificationType: 'PHONE', option: 'SEND_CODE' }, params)
  return postJson(`${base1}setting/profile/supply`, params)
}

// 机机校验
export const humanCheck = (params: { turnstile: { token: string }; key: string }) => {
  return postJson(`${base1}verify/human/check`, params)
}
// 机机校验
export const loginBy2fa = (params: { token: string; code: string }) => {
  return postJson(`${base1}login/by-2fa`, params)
}
